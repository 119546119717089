<template>
  <!-- 导航 -->
  <div class="head-v3">
    <div class="navigation-up">
      <div class="navigation-inner">
        <div class="navigation-v3">
          <ul>
            <li style="margin-right: 100px">
              <h2>厦门浪鲨科技有限公司</h2>
            </li>
            <li class="nav-up-selected-inpage" _t_nav="home">
              <h2>
                <a href="">首页</a>
              </h2>
            </li>
            <li class="" _t_nav="productservice">
              <h2>
                <a href="">产品服务</a>
              </h2>
            </li>
            <li class="" _t_nav="customercase">
              <h2>
                <a href="">客户案例</a>
              </h2>
            </li>
            <li class="" _t_nav="aboutus">
              <h2>
                <a href="">关于我们</a>
              </h2>
            </li>
            <li _t_nav="helpsupport">
              <h2>
                <a href="">帮助与支持</a>
              </h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navigation-down">
      <div
        id="productservice"
        class="nav-down-menu menu-1"
        style="display: none"
        _t_nav="productservice"
      >
        <div class="navigation-down-inner">
          <dl style="margin-left: 100px">
            <dt>管理系统</dt>
            <dd>
              <a hotrep="hp.header.productservice.system1" href=""
                >供应链管理系统</a
              >
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.system2" href=""
                >采购管理系统</a
              >
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.system3" href="">企业OA</a>
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.system4" href="">TPM系统</a>
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.system5" href=""
                >能源管理系统</a
              >
            </dd>
          </dl>
          <dl>
            <dt>网站</dt>
            <dd>
              <a hotrep="hp.header.productservice.web1" href="">企业官网</a>
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.web2" href=""
                >企业产品选型平台</a
              >
            </dd>
          </dl>
          <dl>
            <dt>小程序</dt>
            <dd>
              <a hotrep="hp.header.productservice.mp1" href="">易回收</a>
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.mp2" href="">易签到</a>
            </dd>
            <dd>
              <a hotrep="hp.header.productservice.mp3" href="">易投票</a>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- 轮播图、产品服务 -->
  <div class="banner_product">
    <!-- 轮播图 -->
    <div class="banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="swpier-view">
              <!-- <h2 class="title">AAA</h2>
              <div class="des">aaa</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品服务 -->
    <div class="p-server">
      <div class="p-server-top">
        <div class="p-server-top-left">
          <h2 class="title">产品服务</h2>
          <div class="des">
            依据数据模型、多元化系统支撑，为多领域、全场景提供数字化协同产品，赋能现代企业数字与信息化转型
          </div>
        </div>
      </div>
      <div class="p-server-main">
        <div class="p-serverMain">
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">供应链管理系统</h3>
                </div>
                <div class="bottom">
                  <div class="des">
                    连接供应链上下游，支持数据集采、收发货交易，以及对公账户支付等
                  </div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">采购管理系统</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">企业OA</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">TPM系统</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">能源管理系统</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">企业官网</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">企业产品选型平台</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moreView">
            <div class="p-serverView">
              <div class="p-serverView-top">
                <div class="titleView">
                  <div class="ImgaeView">
                    <img data-src="/" alt="" />
                  </div>
                  <h3 class="title">小程序</h3>
                </div>
                <div class="bottom">
                  <div class="des"></div>
                  <div class="buttonTabs">
                    <div>
                      <a href="javascript:void();" class="demo_application_btn"
                        >系统演示</a
                      >
                    </div>
                    <a class="hoverClassa" href="www." rel="" target="_blank"
                      >产品详情 &gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="auto-box">
    <div class="main-box">
      <div class="index-tit">
        <h1></h1>
        <p>近20年 IT 服务经验，面向多元化企业的全程服务</p>
      </div>
      <ul class="index-tserver">
        <!-- <li class="tserver-list1">
          AAA
          <p class="animated zoomin">
            <a href="#">aaa</a>
          </p>
        </li> -->
        <li class="tserver-list2">
          <p class="animated zoomin">
            <a href="#"></a>
          </p>
        </li>
        <li class="tserver-list3">
          <p class="animated zoomin">
            <a href="#"></a>
          </p>
        </li>
        <li class="tserver-list4">
          <p class="animated zoomin">
            <a href="#"></a>
          </p>
        </li>
        <li class="tserver-list5">
          <p class="animated zoomin">
            <a href="#"></a>
          </p>
        </li>
        <li class="tserver-list6">
          <p class="animated zoomin">
            <a href="#"></a>
          </p>
        </li>
        <li class="tserver-list7">
          <p class="animated zoomin">
            <a href="#"></a>
          </p>
        </li>
      </ul>
    </div>
  </div>
  <!-- 呼吸灯 -->
  <div class="breathing_lamp">
    <div class="circle_box">
      <div class="round">
        <div class="circle"><a>供应链</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"><a>采购进销</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"><a>OA</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle">
          <a>设备<br />TPM</a>
        </div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"><a>能耗</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"><a>企业官网</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"><a>小程序</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"><a>微官网</a></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"></div>
        <div class="circle_bottom animation"></div>
        <div class="circle_bottom2 animation2"></div>
      </div>
      <div class="round">
        <div class="circle"></div>
      </div>
      <div class="round">
        <div class="circle"></div>
      </div>
      <div class="round">
        <div class="circle"></div>
      </div>
    </div>
  </div>
  <!-- 菱形 -->
  <div class="diamond">
    <div class="diamond-box">
      <ul class="one">
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
        </li>
        <li style="margin-left: -145px">
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg1">
              <p class="num">1</p>
              <!-- <p class="title">AAA</p>
              <p class="eng">aaa</p> -->
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <!-- <p class="desc">
              1aaa
            </p> -->
          </div>
        </li>
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg2">
              <p class="num">2</p>
              <p class="title"></p>
              <p class="eng"></p>
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <p class="desc"></p>
          </div>
        </li>
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg3">
              <p class="num">3</p>
              <p class="title"></p>
              <p class="eng"></p>
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <p class="desc"></p>
          </div>
        </li>
        <li style="margin-left: -145px">
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
        </li>
      </ul>
      <ul class="two">
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg4">
              <p class="num">4</p>
              <p class="title"></p>
              <p class="eng"></p>
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <p class="desc"></p>
          </div>
        </li>
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg5">
              <p class="num">5</p>
              <p class="title"></p>
              <p class="eng"></p>
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <p class="desc"></p>
          </div>
        </li>
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg6">
              <p class="num">6</p>
              <p class="title"></p>
              <p class="eng"></p>
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <p class="desc"></p>
          </div>
        </li>
        <li>
          <div class="kuang kuang1"></div>
          <div class="kuang kuang2"></div>
          <div class="text1">
            <div class="bg bg7">
              <p class="num">7</p>
              <p class="title"></p>
              <p class="eng"></p>
            </div>
          </div>
          <div class="text2">
            <div class="mengban"></div>
            <p class="desc"></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- 底部 -->
  <div class="footer0">
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-text">构建企业迅捷管理系统</div>
    <div class="bubble-link">
      <a href="#" target="">立即试用</a>
    </div>
  </div>
  <!-- 底部 -->
  <div class="footer">
    <div class="footer-box">
      <div class="footer-nav clearfix">
        <!-- 左侧 -->
        <div class="footer-nav-logo">
          <div class="comm">
            <!-- <div class="comm-logo">
              <span></span>
            </div> -->
            <div class="info">
              <p>厦门浪鲨科技有限公司</p>
              <p>TEL:18405033520</p>
            </div>
          </div>
          <!-- 微博、微信、QQ、邮箱 -->
          <div class="share">
            <p class="wei-xin">
              <span class="corner wx-corner"></span>
            </p>
            <p class="wei-bo">
              <span class="corner wx-corner"></span>
            </p>
            <ul class="clearfix">
              <li>
                <a href="#">
                  <span class="icon-blog"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="icon-wei"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="icon-qq"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="icon-mail"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="footer-nav-list clearfix">
          <ul>
            <li class="list-title">产品服务</li>
            <li>
              <a href="#">供应链管理系统</a>
            </li>
            <li>
              <a href="#">采购管理系统</a>
            </li>
            <li>
              <a href="#">企业OA</a>
            </li>
            <li>
              <a href="#">TPM系统</a>
            </li>
            <li>
              <a href="#">能源管理系统</a>
            </li>
            <li>
              <a href="#">企业官网选型</a>
            </li>
            <li>
              <a href="#">易回收</a>
            </li>
            <li>
              <a href="#">易投票</a>
            </li>
            <li>
              <a href="#">易签到</a>
            </li>
          </ul>
          <ul>
            <li class="list-title">帮助与支持</li>
            <li>
              <a href="#">客户案例</a>
            </li>
            <li>
              <a href="#">产品价格</a>
            </li>
            <li>
              <a href="#">产品服务</a>
            </li>
            <li>
              <a href="#">帮助文档</a>
            </li>
          </ul>
          <ul>
            <li class="list-title">关于我们</li>
            <li>
              <a href="#">公司简介</a>
            </li>
            <li>
              <a href="#">发展历程</a>
            </li>
            <li>
              <a href="#">联系我们</a>
            </li>
            <li>
              <a href="#">新闻动态</a>
            </li>
            <li>
              <a href="#">加入我们</a>
            </li>
          </ul>
          <ul>
            <li class="list-title">其他</li>
            <li>
              <a href="#">意见反馈</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 版权 -->
    <div class="copyright copyright-about">
      <p>
        Copyright © 2024-2025 langshark.com. All Rights Reserved.
        厦门浪鲨科技有限公司 版权所有
      </p>
      <p>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          target="_blank"
          >闽ICP备2024073195号-1</a
        >
        &nbsp;&nbsp;&nbsp;
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302036392"
          target="_blank"
          ><img
            src="../images/guohui.png"
            style="vertical-align: -20%"
          />&nbsp;&nbsp;闽公网安备35020302036392号 </a
        >&nbsp;&nbsp;&nbsp;地址：福建省厦门市思明区西林东里4号
      </p>
    </div>
  </div>
</template>

<script>
// import extend from "@/components/js/jquery.js";
// import from "http://libs.baidu.com/jquery/2.1.4/jquery.min.js";

// import { ready } from "@/js/navigation.js";

export default {
  name: "IndexPage",
  props: {},
};
</script>
  
<style scoped>
/* 公共样式表css */
html,
body {
  color: #333;
  margin: 0;
  height: 100%;
  font-family: "Myriad Set Pro", "Helvetica Neue", Helvetica, Arial, Verdana,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  border: 0;
}

body {
  background: #fff;
  color: #666;
}

html,
body,
div,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
button,
fieldset,
form,
input,
legend,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #08acee;
}

button {
  outline: 0;
}

img {
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  outline: none;
}

li {
  list-style: none;
}

a {
  color: #666;
}

a:hover {
  color: #eee;
}

.clearfix::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.clearfix {
}

/* 导航 */
.head-v3 {
  position: relative;
  z-index: 100;
  min-width: 1200px;
  margin: 0;
  padding: 0;
}
.head-v3 .navigation-inner {
  margin: 0 auto;
  width: 1180px;
  position: relative;
}
.head-v3 h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-family: arial, "hiragino sans gb", "microsoft yahei", "Simsun",
    sans-serif;
}
.navigation-up {
  height: 60px;
  background: #27303f;
}
.navigation-up .navigation-v3 {
  margin-left: 155px;
  float: left;
  _margin-left: 10px;
}
.navigation-up .navigation-v3 ul {
  float: left;
}
.navigation-up .navigation-v3 li {
  float: left;
  font: normal 16px/59px "microsoft yahei";
  color: #fff;
}
.navigation-up .navigation-v3 .nav-up-selected {
  background: #344157;
}
.navigation-up .navigation-v3 .nav-up-selected-inpage {
  background: #202833;
}
.navigation-up .navigation-v3 li h2 {
  font-weight: normal;
  padding: 0;
  margin: 0;
}
.navigation-up .navigation-v3 li h2 a {
  padding: 0 25px;
  color: #fff;
  display: inline-block;
  height: 60px;
  font-family: "microsoft yahei";
}

.navigation-down {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
}
.navigation-down .nav-down-menu {
  width: 100%;
  margin: 0;
  background: #344157;
  position: absolute;
  top: 0px;
}
.navigation-down .nav-down-menu .navigation-down-inner {
  margin: auto;
  width: 1200px;
  position: relative;
}
.navigation-down .nav-down-menu dl {
  float: left;
  margin: 18px 80px 18px 0;
}
.navigation-down .menu-1 dl {
  margin: 20px 80px 25px 0;
}
.navigation-down .menu-1 dt {
  font: normal 16px "microsoft yahei";
  color: #61789e;
  padding-bottom: 10px;
  border-bottom: 1px solid #61789e;
  margin-bottom: 10px;
}
.navigation-down .menu-1 dd a {
  color: #fff;
  font: normal 14px/30px "microsoft yahei";
}
.navigation-down .menu-1 dd a:hover {
  color: #60aff6;
}
.navigation-down .menu-2 dd a,
.navigation-down .menu-3 dd a {
  color: #fff;
  font: normal 16px "microsoft yahei";
}

/* 轮播图、产品服务 */
.banner_product {
  /* width: 100%; */
  min-width: 1200px;
  background: #f7f8fa;
}
.banner_product .banner {
  width: 100%;
  position: relative;
}
.banner .swiper-container {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(../images/banner1.png);
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
/* .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
} */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.banner_product .banner .swiper-slide {
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-start;
}
.banner_product .banner .swpier-view {
  margin-top: 156px;
  width: 1200px !important;
  box-sizing: border-box;
  text-align: left;
  margin-left: 5%;
}
.banner_product .banner .swpier-view .title {
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 36px;
  color: #ffffff;
  line-height: 36px;
}
.banner_product .banner .swpier-view .des {
  opacity: 0.6;
  width: 690px;
  margin-top: 48px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;
}
.p-server {
  padding-top: 80px;
  padding-bottom: 56px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* width: 1200px; */
  width: 100%;
  margin: 0 auto;
}
.p-server .p-server-top {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
}
.p-server .p-server-top .p-server-top-left {
  width: 588px;
  font-family: PingFangSC-Regular;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.p-server .p-server-top .p-server-top-left .title {
  font-family: PingFangSC-Regular;
  margin-bottom: 24px;
  width: 100%;
  font-weight: 400;
  font-size: 30px;
  color: #1f2c3d;
}
.p-server .p-server-top .p-server-top-left .des {
  width: 100%;
  height: 44px;
  font-family: PingFangSC-Regular;
  color: rgba(96, 98, 102, 0.8);
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #606266;
}
.p-server .p-server-top .p-server-top-right {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 342px;
  box-sizing: border-box;
}
.p-server .p-server-top .titleImage {
  position: absolute;
  /* background-image: url(../images/banner_productOne/service.png); */
  background-size: 100%;
  background-repeat: no-repeat;
  width: 304px;
  height: 61px;
  top: 11px;
}
.p-server .p-server-top .desTbas {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 7px;
  height: 34px;
  box-sizing: border-box;
}
.p-server .p-server-top .desTbas .span {
  padding-bottom: 16px;
  font-family: PingFangSC-Regular;
  height: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #1f2c3d;
}
.p-server .p-server-top .desTbas .spanHover {
  color: #08acee;
  border-bottom: 2px solid #08acee;
}
.p-server-main {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.p-server-main .p-serverMain {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  opacity: 1;
  transition: 0.5s;
}
.p-server-main .p-serverMain .moreView {
  transition: 0.5s ease-in-out;
  margin-right: 18px;
  position: relative;
  z-index: 1;
  width: 282px;
  height: 282px;
  margin-bottom: 24px;
}
.p-server-main .p-serverMain .moreView:hover {
  margin-top: -10px;
}
.p-server-main .p-serverMain .p-serverView {
  border-radius: 4px;
  cursor: pointer;
  width: 282px;
  height: 282px;
  background: #ffffff;
  padding: 24px 0px 0px 24px;
  box-sizing: border-box;
  background-size: 100% 100%;
  transition: 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.p-server-main .p-serverMain .p-serverView:nth-child(4) {
  margin-right: 0px;
}
.p-server-main .p-serverMain .p-serverView:nth-child(8) {
  margin-right: 0px;
}
.p-serverView-top .titleView {
  margin-bottom: 24px;
}
.p-serverView-top .titleView .title {
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  color: #1f2c3d;
}
.p-serverView-top .ImgaeView {
  width: 48px;
  height: 48px;
  background: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 24px;
}
.p-serverView-top .ImgaeView img {
  width: 24px;
  height: 24px;
}
.p-serverView-top .bottom .des {
  width: 234px;
  height: 44px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #606266;
}
.p-serverView-top .bottom .buttonTabs {
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
}
.p-serverView-top .bottom .buttonTabs a {
  width: 96px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: black;
}
.p-serverView-top .bottom .buttonTabs div {
  width: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-serverView-top .bottom .buttonTabs div a {
  font-size: 14px;
  line-height: 36px;
  height: 36px;
}
.p-serverView-top .bottom .buttonTabs div:nth-child(1) {
  box-sizing: border-box;
  background: white;
  font-weight: 400;
  font-size: 14px;
  color: #08acee;
  height: 36px;
  border: 1px solid #08acee;
  border-radius: 4px;
}
.p-serverView-top .bottom .buttonTabs div:nth-child(1) a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #08acee;
  font-weight: 400;
  font-size: 14px;
}
.p-serverView-top .bottom .buttonTabs div:nth-child(1) a:hover {
  background: #08acee;
  border-radius: 4px;
  color: #fff;
}
.p-serverView-top .bottom .buttonTabs div:nth-child(2) a {
  font-weight: 400;
  font-size: 14px;
  color: #1f2c3d;
}
.p-serverView-top .bottom .buttonTabs a:hover {
  color: #08acee;
}

/*  */
.auto-box {
  width: 100%;
  height: auto;
  padding: 30px 0 40px;
  background: #fff;
}
.main-box {
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
}
.index-tit {
  padding: 40px 0;
  text-align: center;
}
.index-tit h1 {
  font-size: 30px;
  color: #363f45;
  font-weight: normal;
}
.index-tit p {
  font-size: 20px;
  color: #abb0b4;
  padding-top: 15px;
}
.index-tserver {
  width: 100%;
  background: url("../images/360server-line.png") no-repeat center 125px;
  box-sizing: border-box;
  text-align: center;
  padding: 0 2.6%;
}
.index-tserver li {
  width: 24.8%;
  min-width: 162px;
  height: 162px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 106px;
  font-size: 14px;
  color: #656d73;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: -0.1%;
}
.index-tserver li.tserver-list1 {
  background: url("../images/360server-icon1.png") no-repeat center top;
}
.index-tserver li.tserver-list2 {
  background: url("../images/360server-icon2.png") no-repeat center top;
}
.index-tserver li.tserver-list3 {
  background: url("../images/360server-icon3.png") no-repeat center top;
}
.index-tserver li.tserver-list4 {
  background: url("../images/360server-icon4.png") no-repeat center top;
}
.index-tserver li.tserver-list5 {
  background: url("../images/360server-icon5.png") no-repeat center top;
}
.index-tserver li.tserver-list6 {
  background: url("../images/360server-icon6.png") no-repeat center top;
}
.index-tserver li.tserver-list7 {
  background: url("../images/360server-icon7.png") no-repeat center top;
}
.index-tserver li p {
  width: 162px;
  height: 162px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -81px;
  border-radius: 81px;
  padding: 40px 30px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  background: linear-gradient(111deg, #04bde4, #1067fe);
  display: none;
}
.index-tserver li p a {
  color: #fff;
}
.index-tserver li p.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.index-tserver li:hover p {
  display: block;
}
.index-tserver-ad {
  width: 100%;
  margin-top: 60px;
  background: #f3f3f3;
  text-align: center;
  padding: 40px 0;
}
.index-tserver-ad li {
  display: inline-block;
  color: #abb0b4;
  font-size: 16px;
  padding: 0 3%;
}
.index-tserver-ad li img {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
.index-tserver-ad li + li {
  border-left: 1px solid #e2e3e4;
}
.zoomin {
  animation-name: zoomin;
}
@keyframes zoomin {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
  }
}

/* 呼吸灯 */
.breathing_lamp {
  background: #422710;
  font-family: "微软雅黑", Arial;
  font-size: 16px;
  color: #fff;
  overflow: -Scroll;
  overflow-x: hidden;
  margin-bottom: 20px;
  min-width: 1200px;
}
.circle_box {
  width: 978px;
  margin: 50px auto;
  height: 317px;
  position: relative;
}
.round:nth-child(1) {
  position: absolute;
  padding: 13px;
  left: 0px;
  top: 79px;
}
.round:nth-child(1) div {
  background: #3a96e1;
}
.round:nth-child(1) div:first-child {
  width: 100px;
  height: 100px;
}
.round:nth-child(1) div:first-child a {
  line-height: 99px;
}
.round:nth-child(1) div:nth-child(2) {
  width: 100px;
  height: 100px;
}
.round:nth-child(1) div:last-child {
  width: 110px;
  height: 110px;
}
.round:nth-child(2) {
  position: absolute;
  left: 176px;
  top: 0;
  padding: 13px;
}
.round:nth-child(2) div {
  background: #c1bd57;
}
.round:nth-child(3) {
  position: absolute;
  left: 199px;
  top: 199px;
  padding: 13px;
}
.round:nth-child(3) div {
  background: #d67c61;
}
.round:nth-child(3) div:first-child {
  width: 80px;
  height: 80px;
}
.round:nth-child(3) div:first-child a {
  line-height: 80px;
}
.round:nth-child(3) div:nth-child(2) {
  width: 80px;
  height: 80px;
}
.round:nth-child(3) div:last-child {
  width: 90px;
  height: 90px;
}
.round:nth-child(4) {
  position: absolute;
  left: 348px;
  top: 128px;
  padding: 13px;
}
.round:nth-child(4) div {
  background: #307cc2;
}
.round:nth-child(4) div:first-child {
  width: 90px;
  height: 90px;
}
.round:nth-child(4) div:first-child a {
  line-height: 24px;
  display: block;
  margin-top: 25px;
}
.round:nth-child(4) div:nth-child(2) {
  width: 90px;
  height: 90px;
}
.round:nth-child(4) div:last-child {
  width: 100px;
  height: 100px;
}
.round:nth-child(5) {
  position: absolute;
  left: 519px;
  top: 94px;
  padding: 13px;
}
.round:nth-child(5) div:first-child {
  width: 70px;
  height: 70px;
}
.round:nth-child(5) div:first-child a {
  line-height: 70px;
}
.round:nth-child(5) div:nth-child(2) {
  width: 70px;
  height: 70px;
}
.round:nth-child(5) div:last-child {
  width: 80px;
  height: 80px;
}
.round:nth-child(6) {
  position: absolute;
  left: 705px;
  top: 10px;
  padding: 13px;
}
.round:nth-child(6) div {
  background: #307cc2;
}
.round:nth-child(7) {
  position: absolute;
  left: 638px;
  top: 171px;
  padding: 13px;
}
.round:nth-child(7) div {
  background: #c1bd57;
}
.round:nth-child(8) {
  position: absolute;
  left: 860px;
  top: 113px;
  padding: 13px;
}
.round:nth-child(8) div:first-child {
  width: 75px;
  height: 75px;
}
.round:nth-child(8) div:first-child a {
  line-height: 70px;
  font-size: 16px;
}
.round:nth-child(8) div:nth-child(2) {
  width: 75px;
  height: 75px;
}
.round:nth-child(8) div:last-child {
  width: 85px;
  height: 85px;
}
.round:nth-child(9) {
  position: absolute;
  left: 509px;
  top: 253px;
  padding: 13px;
}
.round:nth-child(9) div {
  background: #a36157;
}
.round:nth-child(9) div:first-child {
  width: 30px;
  height: 30px;
}
.round:nth-child(9) div:nth-child(2) {
  width: 30px;
  height: 30px;
}
.round:nth-child(9) div:last-child {
  width: 40px;
  height: 40px;
}
.round:nth-child(10) {
  position: absolute;
  left: 630px;
  top: 5px;
  padding: 13px;
}
.round:nth-child(10) div {
  background: #2663a4;
}
.round:nth-child(10) div:first-child {
  width: 40px;
  height: 40px;
}
.round:nth-child(10) div:nth-child(2) {
  width: 40px;
  height: 40px;
}
.round:nth-child(10) div:last-child {
  width: 50px;
  height: 50px;
}
.round:nth-child(11) {
  position: absolute;
  left: 149px;
  top: 145px;
  padding: 26px;
}
.round:nth-child(11) div {
  background: #1c4a86;
}
.round:nth-child(11) div:first-child {
  width: 20px;
  height: 20px;
}
.round:nth-child(12) {
  position: absolute;
  left: 366px;
  top: 69px;
  padding: 26px;
}
.round:nth-child(12) div {
  background: #7e4c4f;
}
.round:nth-child(12) div:first-child {
  width: 10px;
  height: 10px;
}
.round:nth-child(13) {
  position: absolute;
  left: 467px;
  top: 40px;
  padding: 30px;
}
.round:nth-child(13) div {
  background: #0e295d;
}
.round:nth-child(13) div:first-child {
  width: 30px;
  height: 30px;
}
.round:nth-child(12) {
  position: absolute;
  left: 846px;
  top: 243px;
  padding: 30px;
}
.round:nth-child(12) div {
  background: #1e539e;
}
.round:nth-child(12) div:first-child {
  width: 20px;
  height: 20px;
}
.circle a {
  font-size: 18px;
  line-height: 100px;
  color: #000735;
  font-weight: bold;
}
.circle {
  background: #3a96e1;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  z-index: 999;
  text-align: center;
  top: 18px;
  left: 18px;
  position: absolute;
}
.circle_bottom {
  background: rgba(58, 150, 225, 0.4);
  border-radius: 100%;
  width: 100px;
  height: 100px;
  filter: alpha(opacity=40);
  z-index: -100;
  position: absolute;
  top: 18px;
  left: 18px;
}
.circle_bottom2 {
  background: rgba(58, 150, 225, 0.2);
  border-radius: 100%;
  width: 110px;
  height: 110px;
  filter: alpha(opacity=20);
  z-index: -110;
  position: relative;
}
.animation {
  -webkit-animation: twinkling 2.1s infinite ease-in-out;
  animation: twinkling 2.1s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animation2 {
  -webkit-animation: twinkling 2.1s infinite ease-in-out;
  animation: twinkling 2.1s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes twinkling {
  0% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -webkit-transform: scale(1.12);
  }
  100% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
}
@keyframes twinkling {
  0% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -webkit-transform: scale(1.12);
  }
  100% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
}

/* 菱形 */
.diamond {
  width: 1140px;
  margin: 0 auto;
  overflow: hidden;
}
.diamond-box {
  width: 1140px;
  height: 450px;
}
.diamond-box ul {
  width: 1140px;
}
.diamond-box ul li {
  width: 196px;
  height: 196px;
  float: left;
  cursor: pointer;
  position: relative;
}
.diamond-box ul li .kuang {
  position: absolute;
  width: 196px;
  height: 196px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px solid #ddd;
  margin-top: 42px;
  margin-left: 42px;
}
.diamond-box ul li .kuang1 {
  top: 0;
  left: 0;
  z-index: 1;
}
.diamond-box ul li .kuang2 {
  left: 5px;
  top: 0px;
  z-index: 2;
}
.diamond-box ul li .text1 {
  width: 276px;
  height: 276px;
  text-align: center;
  z-index: 3;
  position: absolute;
  left: 7px;
  top: 2px;
}
.diamond-box ul li .text1 .bg {
  width: 276px;
  height: 276px;
}
.diamond-box ul li .text1 .num {
  padding-top: 50px;
  font-size: 86px;
  color: #dacdc2;
  font-weight: 700;
}
.diamond-box ul li .text1 .title {
  font-size: 15px;
  color: #422710;
}
.diamond-box ul li .text1 .eng {
  font-size: 12px;
  color: #b4a078;
}
.diamond-box ul li .text2 {
  width: 276px;
  height: 276px;
  z-index: 4;
  position: absolute;
  left: 5px;
  top: 0px;
  display: none;
}
.diamond-box ul li .text2 .mengban {
  border: 1px solid #533b26;
  position: absolute;
  z-index: 1;
  width: 196px;
  height: 196px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #533b26;
  margin-top: 42px;
  margin-left: 42px;
}
.diamond-box ul li .text2 .desc {
  position: absolute;
  z-index: 2;
  width: 140px;
  height: 110px;
  top: 88px;
  left: 70px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
}
.diamond-box ul li {
  margin-right: 89px;
}
.diamond-box ul.two li {
  margin-top: -40px;
}
.diamond-box ul li:hover .text2 {
  display: block;
}

/* 底部 */
.footer0 {
  position: relative;
  min-width: 1200px;
  background: #0af url(../images/back.jpg) no-repeat center;
  height: 180px;
  overflow: hidden;
  color: #fff;
  text-align: center;
}

.bubble-one {
  position: absolute;
  top: -120px;
  left: 50%;
  z-index: 10;
  margin-left: -120px;
  width: 240px;
  height: 240px;
  background: url(../images/back-bule1.png);
  animation: bubble-animate-1 linear 10s infinite;
}

.bubble-two {
  position: absolute;
  top: 50px;
  left: 34%;
  z-index: 11;
  width: 360px;
  height: 360px;
  background: url(../images/back-bule2.png);
  animation: bubble-animate-2 linear 12s infinite;
}

.bubble-three {
  position: absolute;
  top: 50px;
  left: 48%;
  z-index: 12;
  width: 300px;
  height: 300px;
  background: url(../images/back-bule3.png);
  animation: bubble-animate-3 linear 11s infinite;
}

@keyframes bubble-animate-1 {
  from {
    top: -120px;
  }

  50% {
    top: -180px;
  }

  to {
    top: -120px;
  }
}

@keyframes bubble-animate-2 {
  from {
    top: 50px;
    left: 34%;
  }

  50% {
    top: 80px;
    left: 24%;
  }

  to {
    top: 50px;
    left: 34%;
  }
}

@keyframes bubble-animate-3 {
  from {
    top: 50px;
    left: 48%;
  }

  50% {
    top: 80px;
    left: 58%;
  }

  to {
    top: 50px;
    left: 48%;
  }
}

.bubble-text {
  position: relative;
  padding-top: 46px;
  z-index: 20;
  font-size: 24px;
}

.bubble-link {
  position: relative;
  z-index: 20;
}

.bubble-link a {
  display: inline-block;
  margin-top: 24px;
  width: 220px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 10px;
}

.bubble-link a:hover {
  /* color: #333; */
  color: #08acee;
  background-color: #fff;
}

.footer {
  min-width: 1200px;
  background-color: #2b373d;
  font-family: "Microsoft Yahei";
}

.footer-box {
  width: 1200px;
  margin: 0 auto;
}

.footer-feature {
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #414b51;
}

.footer-feature ul li {
  float: left;
  width: 25%;
  height: 120px;
}

.footer-feature ul li a {
  white-space: nowrap;
  display: block;
  line-height: 120px;
  padding-left: 110px;
  background-repeat: no-repeat;
  background-position: 50px center;
  font-size: 18px;
  color: #fff;
}

.feature-item001 a {
  background-image: url("../images/icon-item001.png");
}

.feature-item002 a {
  background-image: url("../images/icon-item002.png");
}

.feature-item003 a {
  background-image: url("../images/icon-item003.png");
}

.feature-item004 a {
  background-image: url("../images/icon-item004.png");
}

.footer-nav {
  width: 100%;
  padding: 40px 0 62px;
}

.footer-nav-logo {
  width: 32%;
  float: left;
}

.comm {
  padding-left: 20px;
}

.comm-logo {
  height: 40px;
  background: url(../images/logo-footer.png) no-repeat left;
  background-size: 115px;
}

.comm-logo span {
  display: inline-block;
  margin-left: 136px;
  margin-top: -5px;
  width: 126px;
  height: 40px;
  background: url(../images/icon-gif.gif) no-repeat left;
}

.info {
  margin-top: 20px;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
  text-align: left;
}

.share {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  position: relative;
}

.share > p {
  width: 132px;
  height: 132px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.wei-xin {
  position: absolute;
  top: -145px;
  left: 24px;
  background-image: url(../images/icon-ewm.jpg);
  display: none;
  background-size: 132px;
}

.corner {
  border: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  bottom: -20px;
  right: 50%;
  margin-right: -10px;
  z-index: 10;
}

.wei-bo {
  position: absolute;
  top: -145px;
  left: -32px;
  background-image: url(../images/wb.png);
  display: none;
  background-size: 132px;
}

.share ul li {
  float: left;
  margin-left: 15px;
  width: 40px;
  height: 40px;
}

.share ul li a {
  display: block;
}

.share ul li a span {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 40px;
}

.share ul li .icon-blog {
  background-image: url(../images/wb1.png);
}

.share ul li .icon-blog:hover {
  background-image: url(../images/wb2.png);
}

.share ul li .icon-wei {
  background-image: url(../images/wx1.png);
}

.share ul li .icon-wei:hover {
  background-image: url(../images/wx2.png);
}

.share ul li .icon-qq {
  background-image: url(../images/qq1.png);
}

.share ul li .icon-qq:hover {
  background-image: url(../images/qq2.png);
}

.share ul li .icon-mail {
  background-image: url(../images/yx1.png);
}

.share ul li .icon-mail:hover {
  background-image: url(../images/yx2.png);
}

.footer-nav-list {
  width: 68%;
  height: 100%;
  border-left: 1px solid #414b51;
  padding-left: 20px;
  float: left;
}

.footer-nav-list ul {
  float: left;
  width: 25%;
}

.list-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
  line-height: 30px;
}

.footer-nav-list ul li {
  line-height: 30px;
}

.footer-nav-list ul li a {
  color: #819199;
  font-size: 13px;
  /* font-weight: 600; */
  text-decoration: none;
}

.footer-nav-list ul li a:hover {
  /* color: #fff; */
  color: #08acee;
  text-decoration: underline;
}

.copyright {
  width: 100%;
  border-top: 1px solid #212a2f;
  padding: 20px 0;
  text-align: center;
}

.copyright p {
  text-align: center;
  color: #819199;
  font-size: 12px;
  line-height: 25px;
}

.copyright p a {
  color: #819199;
}

.copyright p a:hover {
  color: #08acee;
  text-decoration: underline;
}
</style>
  